/*
    Created on : 31 May 2022, 3:46:07 PM
    Author     : Dakalo
*/

:root {
    --primary: #222B66;
    --secondary: #F05223;
    --secondary-darker: #CF461E;
    --tertiary: #848383;
    --tertiary-lighter: #D8D8D8;
}

@font-face {
    font-family: futura;
    src: url('../fonts/futura_regular.ttf');
}
@font-face {
    font-family: futura;
    src: url('../fonts/futura_bold.ttf');
    font-weight: bold;
}
@font-face {
    font-family: futura;
    src: url('../fonts/futura_italic.ttf');
    font-style: italic;
}

body {
    font-family: futura;
    background-color: var(--primary);
}

a {
    color: var(--secondary);
    text-decoration: none;
}

a:active, a:hover {
    color: var(--secondary-darker);
}

.auth-body {
    background-color: var(--tertiary-lighter);
    background-image: url('../img/truck_wallpaper.jpg');
    background-size: cover;
    background-position: top left;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.auth-nav {
    text-align: right;
}

.page-content {
    min-height: 88vh;
}

.page-container {
    margin: 70px auto;
}

.page-container-system {
    background-color: #fff;
    margin-top: 30px;
    margin-bottom: 30px;
    border: var(--tertiary) solid 2px;
    padding: 1.2rem;
}

.page-container-system h1 {
    color: var(--secondary);
    font-weight: bold;
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.form-subnav span {
    height: 40px;
    background-color: var(--secondary);
    color: #fff;
    display: inline-block;
    font-weight: bold;
    padding: 7px;
    border-bottom-right-radius: 10px;
}

.transaction-title {
    height: 40px;
    background-color: var(--secondary);
    color: #fff;
    display: inline-block;
    font-weight: bold;
    padding: 7px;
    border-bottom-right-radius: 10px;
}

.map-container {
    border: solid var(--tertiary-lighter) 2px;
    min-height: 600px;
    width: 100%;
}

.map-container-min {
    border: solid var(--tertiary-lighter) 2px;
    min-height: 230px;
    width: 100%;
}

.ifuel-input-icon {
    height: 50px;
}

.ifuel-input {
    height: 50px;
    border-radius: 0px;
    border-width: 2px;
}

.text-muted {
    color: var(--tertiary) !important;
}

.text-primary {
    color: var(--primary) !important;
}

.text-secondary {
    color: var(--secondary) !important;
}

#ifuel-nav {
    background: var(--primary)
}

#ifuel-nav .navbar-nav .nav-link  {
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
    padding-left: 15px;
    padding-right: 15px;
}

#ifuel-nav .navbar-nav .nav-link:hover  {
    color: var(--secondary);
}

#ifuel-nav .navbar-nav .nav-link.active  {
    color: var(--primary);
    background: #fff;
}

#ifuel-nav .navbar-nav .nav-link.active:hover  {
    color: var(--secondary);
}

#ifuel-footer {
    padding: 0.8rem;
    color: #333333;
    background: var(--tertiary-lighter);
    text-align: right;
}

.clearfix {
    clear: both;
}

.btn-block {
    width: 100%;
}

.btn-ifuel {
    color: #fff;
    background-color: var(--secondary);
    border-color: var(--secondary);
    height: 50px;
    border-radius: 0px;
    font-weight: bold;
}

.btn-ifuel:hover {
    color: #fff;
    background-color: var(--secondary-darker);
    border-color: var(--secondary-darker);
}

.btn-alternative {
    color: #000;
    background-color: var(--tertiary-lighter);
    border-color: var(--tertiary);
    border-width: 2px;
    height: 50px;
    border-radius: 0px;
    font-weight: bold;
}

.btn-alternative:hover {
    color: #fff;
    background-color: var(--tertiary);
    border-color: var(--tertiary);
}

.btn-image {
    height: 40px;
    background: transparent;
    padding: 0px;
    border: none;
    border-radius: 0px;
}

.btn-image img {
    width: auto;
    height: 100%;
}

.btn-edit-boxed {
    float: right;
    border: solid var(--tertiary) 2px;
    padding: 5px;
}

.panel-logo {
    background: var(--primary);
}

.panel-form {
    background-color: #fff;
    padding: 10px;
}



.login-help {
    text-align: right;
}

.auth-form {
    display: block;
    margin: 100px auto;
    max-width: 400px;
}

.page-container h1 {
    font-weight: bold;
    margin-bottom: 1rem;
    text-transform: uppercase;
    font-size: 2rem;
}

.page-container.page-mini {
    max-width: 650px;
}

.ifuel-card-tab {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.ifuel-card-tab .card-header {
    background-color: var(--secondary);
    color: #fff;
    font-weight: bold;
    font-size: 1.2rem;
    display: inline-block;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    padding-left: 2rem;
    padding-right: 2rem;
}

.ifuel-card-tab .card-body {
    border: solid var(--tertiary-lighter) 2px;
}

.ifuel-card-tab .card-close {
    float: right;
}

.ifuel-card-tab .card-label {
    padding: 1rem 0px;
}

.ifuel-card-tab .card-footer {
    border: none;
}

.page-container-system .footer-wallpaper {
    margin-left: -1.2rem;
    margin-right: -1.2rem;
    margin-bottom: -1.2rem;
}

.page-container-system .footer-wallpaper img {
    width: 100%;
    height: auto;
}

.ifuel-modal .modal-content {
    border-radius: 0px;
}

#globalIfuelModalContent h2 {
    font-weight: bold;
    margin-bottom: 2rem;
}

.ifuel-payment-box {
    border: solid var(--tertiary) 2px;
    padding: 1rem;
    border-radius: 7px;
    background-color: #ebebeb;
}

.ifuel-payment-box .box-header {
    font-size: 1.5rem;
    font-weight: bold;
    border-bottom: solid var(--tertiary) 1px;
    padding-bottom: 10px;
    text-transform: uppercase;
}

.special-box-confirm {
    border: solid var(--secondary) 2px;
    padding: 0.8rem;
    border-radius: 5px;
    background-color: var(--tertiary-lighter);
}

.special-box-confirm p {
    margin-bottom: 0.5rem;
    text-align: center;
    font-weight: bold;
}

/* PAGE LIST SYSTEM */
.page-list-system {
    margin-top: 1.5rem;
}

.page-list-system h1 {
    color: #fff;
    font-weight: bold;
    font-size: 2rem;
    text-transform: uppercase;
}

.btn-ifuel-search {
    background-color: rgba(255, 255, 255, 0.5);
    height: 38px;
    padding: 8px;
}

.form-search {
    border-radius: 0px;
    border: solid var(--secondary) 1px;
}

.btn-tab-gray {
    background-color: var(--tertiary-lighter);
    --bs-btn-hover-bg: var(--tertiary);
    color: var(--tertiary);
    font-weight: bold;
    font-size: 1.2rem;
    border-radius: 0px;
    border: none;
}

.btn-tab-gray.active {
    background-color: var(--tertiary);
    color: var(--tertiary-lighter);
}

.btn-tab-add {
    background-color: var(--secondary);
    --bs-btn-hover-bg: var(--secondary-darker);
    color: #fff;
    font-weight: bold;
    border-radius: 0px;
    border: none;
}

.btn-tab-add:hover {
    color: var(--tertiary-lighter);
}

.btn-tab-add img {
    height: 36px;
}

.table-ifuel {
    margin-top: 2px;
    margin-bottom: 0px;
}

.table-ifuel thead {
    --bs-table-bg: var(--tertiary);
    color: #fff;
}

.table-ifuel>:not(caption)>*>* {
    padding: 1rem 0.5rem;
}

.table-button {
    height: 25px;
    padding: 0px;
    border: none;
    background: transparent;
}

.table-button img {
    height: 100%;
    width: auto;
    vertical-align: top;
}
