#login-btn{

    margin-top: 30px;
    width: 100%;
}

.main-btn{
    opacity: 1;
    color: rgba(238,238,238,1);
    background-color: #ef5122;
    border-radius: inherit;
    border: 0;
    width: 176%;
    font-size: 14px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 0px;
    text-align: center;
    height: 50px;
}

.grey-btn{
    width: 200px;
    margin-left: 45px;
    color: rgba(8,8,8,1);
    font-size: 14px;
    font-weight: 800;
    background-color: #efefef;
    border: #9b9696 1px solid;
}

.modal-close
{
    width: fit-content;
    margin-left: auto;
}

/*.cred-img*/
/*{*/
/*    width: 60px;*/
/*}*/

.mb-3{
    width: 100%;
}

.input-group-prepend,.cred-img
{
    width: 60px;
    height: 100%;
}

.p-pass
{
    font-weight: 900;
    font-size:15px
}


.profile-div
{
    margin: auto;
    margin-top:20%;
    margin-bottom:20%;
    width: 60%;
    /*display: none;*/
}

.profile-div-logged-in
{
    margin: auto;
    margin-top: 5%;
    margin-bottom: 10%;
    width: 60%;
    display: none;
}

.profile-div-lock
{
    margin: auto;
    margin-top:10%;
    margin-bottom:20%;
    width: 60%;
}

.profile-div-login
{
    margin: auto;
    margin-top:10%;
    margin-bottom:20%;
    width: 80%;
}
h1{
    font-weight: 800;
    margin-bottom: revert;
}



.cred-inputs{
    height: 50px;
    border: 2px solid
}

.login-logo{
    width: inherit!important;
    height: auto!important;
}

.nav-logo{
    width: 50%;
}

#forgot-pass-lbl,#create-new-profile{
    font-weight: bold;
    color: #ef5122;
}


.login-page
{
    background-image: url('/../assets/img/shutterstock_276834887.jpg');
    background-size:cover;                      /* <------ */
    background-repeat:   no-repeat;
    background-position: center center;
}


.profile-image
{
    width: 103px;
    margin-top: 100px;
    margin-left: 31px;
    border: 3px #ef5122 solid;
}

.search-input
{
    border: 1px solid #ef5122!important;
    height: 48px;
    background-color: #222b66 !important;
    color: white;
}

.search-form
{
    margin-left: auto;
    width: 315px !important;
    margin-top: 10px;
}

.search-btn
{
    height: 48px;
    background-color: #222b66 !important;
    color: white;
    border: 1px solid #ef5122!important;
    border-left: 1px #222b66 !important;
}

.profile-lbl
{
    opacity: 1;
    color: rgba(238,238,238,1);
    font-size: 12px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0px;
    margin-left: 33px;
}




.departments-main-div
{
    margin-left: 50px;
    margin-right: 50px;
    margin-top: -20px;
}

.departments-div
{
    background-color: white;
    height: 80%;
    margin: auto;
    margin-top: 10%;
    margin-bottom: 20%;
    width: 90%;
    border: 3px #ef5122 solid;
}

.departments-div > h2{
    margin-top: 60px;
    font-weight: 800;
}

.q-rounded-circle
{
    height: 296px;
    width: 296px;
    background-color: #E6E6E6;
    box-shadow: 0 3px 10px #101010;
}

.q-h1
{
    color: #ef5122;
    font-size: 60px;
}

.nav-h4
{
    color: white;
    font-weight: 800;
    margin-right: 90px;
    font-size: 19px;
}

.nav-h4-u
{
    font-size: 19px;
    color: white;
    font-weight: 800;
}

.q-img
{
    width: 20%;
}

.nav-dot
{
    margin-top: 7px;
    margin-right: 5px;
    height: 10px;
}


.spinner {
    height: 60px;
    width: 60px;
    margin: auto;
    display: flex;
    position: absolute;
    -webkit-animation: rotation .6s infinite linear;
    -moz-animation: rotation .6s infinite linear;
    -o-animation: rotation .6s infinite linear;
    animation: rotation .6s infinite linear;
    border-left: 6px solid rgba(0, 174, 239, .15);
    border-right: 6px solid rgba(0, 174, 239, .15);
    border-bottom: 6px solid rgba(0, 174, 239, .15);
    border-top: 6px solid rgba(0, 174, 239, .8);
    border-radius: 100%;
}

@-webkit-keyframes rotation {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(359deg);
    }
}

@-moz-keyframes rotation {
    from {
        -moz-transform: rotate(0deg);
    }
    to {
        -moz-transform: rotate(359deg);
    }
}

@-o-keyframes rotation {
    from {
        -o-transform: rotate(0deg);
    }
    to {
        -o-transform: rotate(359deg);
    }
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}

#overlay {
    position: absolute;
    display: none;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 2;
    cursor: pointer;
}


.contact-ppl-w
{
    background-color: #ffffff;
    margin-left: 12px;
    width: 98%;
    padding: 12px;
    border: darkgray 2px solid;
    font-size: 0.8em;
    margin-top: -8px;
}

.contact-ppl-g
{
    background-color: #5b5e61;
    color: white;
    font-weight: bolder;
    margin-left: 30px;
    width: 94%;
    padding: 12px;
    display: inline-flex;
}


.contact-ppl-p
{
    font-weight: bolder;
    margin-left: 30px;
    width: 94%;
    padding: 12px;
    display: inline-flex;
}

.p-type
{
    margin-top: 16px;
    border-color: #f05122;
}

.p-label
{
    width: 82%;
    height: 45px;
    color: white;
    background-color: #f05122;
    font-weight: bolder;
    padding-left: 5px;
}

.type-label
{
    width: 80%;
    height: 45px;
    color: white;
    background-color: #f05122;
    font-weight: bolder;
    padding-top: 12px;
    padding-left: 45px;
}

.add-customer-btn
{
    background-color: #f05122!important;
    font-size: x-small;
    font-weight: 600;
    color: white!important;
    border-radius: inherit;
}

#MapLocation
{
    width: 325px;
}


#tooltip {
    position: absolute;
    margin-top: -65px;
    width: 310px;
    background-color: rgb(0, 0, 0, 0.7);
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    margin-left: 35px;
}


.phone{
    width: 45px;
    height: 42px;
    margin-top: 7px;
    margin-left: 5px;
}


/*MAP STYLING*/
